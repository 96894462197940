<template lang="pug">
    section.ai-steps.section
        ._wrap.wrap
            h2._title {{ title }}
            ._list
                ._item(v-for="(item, index) in list" :key="index")
                    ._item-number {{ index + 1 }}
                    ._item-content
                        ._item-title(v-html="item.title")
                        ._item-description(v-html="item.description" v-if="item.description !== 'none'")
                        router-link(v-else to="/welcome/login")
                            ui-button(size="small" variant="rounded" type="bordered" text="Авторизоваться")._auth-btn
            ._footer
                router-link(to="/welcome/login")
                    ui-button(variant="rounded" :text="btn")._btn
</template>
<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiSteps",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false,
        },
    },
    computed: {
        title: (state) => state.$t(`AILanding.steps.titles.${state.ai}`),
        list: (state) =>
            state.$t(`AILanding.steps.list.${state.ai}`),
        btn: (state) => state.$t(`AILanding.steps.btns.${state.ai}`),
    },
};
</script>

<style lang="scss" src="./ai-steps.scss"></style>
