<template lang="pug">
    section.ai-question.section
        ._wrap.wrap
            h2._title {{ title }}
            ._description {{ description }}
</template>

<script>
export default {
    name: "AiQuestion",
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.question.titles.${state.ai}`),
        description: state => state.$t(`AILanding.question.descriptions.${state.ai}`),
    }
};
</script>

<style lang="scss" src="./ai-question.scss"></style>
