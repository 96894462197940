<template lang="pug">
    section.ai-pika-variants.section
        ._wrap.wrap
            h2._title От мемов и коротких видео до фильмов — Pika следует за вашим воображением
            ._description Три способа создания видео и бесконечные возможности
            ._list
                ._aside
                    ._aside-item(v-for="(item, index) in list" :key="index" :class="{'ai-pika-variants__aside-item_active': item.isActive}" @click="changeActiveElement(item)")
                        ._aside-item-icon-box
                            svg-icon(:icon-name="item.icon" added-catalog="ai-pika-variants")._aside-item-icon
                        ._aside-item-info
                            ._aside-item-title {{ item.title }}
                            ._aside-item-description {{ item.description }}
                ._video-box
                    transition(name="fade")
                        video._video(autoplay loop muted v-if="showVideo")
                            source(v-if="activeVideo.icon === 'text-to-video'" :src="list[0].mp4")
                            source(v-if="activeVideo.icon === 'image-to-video'" :src="list[1].mp4")
                            source(v-if="activeVideo.icon === 'video-to-video'" :src="list[2].mp4")
</template>

<script>
export default {
    name: "AiPikaVariants",
    data() {
        return {
            videoName: "text-to-image",
            showVideo: true,
            list: [
                {
                    icon: "text-to-video",
                    title: "Из текста в видео",
                    description:
                        "Pika воспринимает каждое ваше слово — настройте сцену и наблюдайте как она оживает.",
                    isActive: true,
                    mp4: require("./video/Pika-1.mp4"),
                },
                {
                    icon: "image-to-video",
                    title: "Из картинки в видео",
                    description:
                        "Направьте Pika, чтобы превратить ваши любимые фото, картинки, рисунки в захватывающие движущиеся кадры.",
                    isActive: false,
                    mp4: require("./video/Pika-2.mp4"),
                },
                {
                    icon: "video-to-video",
                    title: "Из видео в видео",
                    description:
                        "Pika позволяет вдохнуть новую жизнь в существующие видео. Меняйте в кадре все, что пожелаете.",
                    isActive: false,
                    mp4: require("./video/Pika-3.mp4"),
                },
            ],
        };
    },
    computed: {
        activeVideo: (state) => state.list.find((i) => i.isActive),
    },
    watch: {
        activeVideo: {
            deep: true,
            handler() {
                this.showVideo = false;
                setTimeout(() => {
                    this.showVideo = true;
                }, 100);
            }
        }
    },
    methods: {
        changeActiveElement(item) {
            this.list.forEach((i) => {
                i.isActive = false;
            });
            this.videoName = item.icon;
            item.isActive = true;
        },
    },
};
</script>

<style lang="scss" src="./ai-pika-variants.scss"></style>
