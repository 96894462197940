<template lang="pug">
    section.ai-simple.section
        ._wrap.wrap
            ._inner(:class="`ai-simple__inner_${ai}`")
                h2._title(v-html="title")
                ._description(v-html="description")
                img(:src="image")._preview
                router-link(to="/welcome/login")
                    ui-button(variant="rounded" :text="btn")._btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiSimple",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.simple.titles.${state.ai}`),
        description: state => state.$t(`AILanding.simple.descriptions.${state.ai}`),
        btn: state => state.$t(`AILanding.simple.btns.${state.ai}`),
        image: state => require(`../../../assets/images/ai-simple/${state.ai}.png`)
    }
};
</script>

<style lang="scss" src="./ai-simple.scss"></style>
