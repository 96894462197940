<template lang="pug">
    .face-swap-content
        ai-header(ai="faceSwap")
        ai-hero(ai="faceSwap")
        ai-face-swap-use
        ai-advantages(ai="faceSwap" :is-big="true")
        ai-face-swap-funny
        ai-popular(ai="faceSwap")
        ai-footer(ai="faceSwap")
</template>

<script>
import AiHero from "@/components/ai-landings/ai-hero/ai-hero.vue";
import AiAdvantages from "@/components/ai-landings/ai-advantages/ai-advantages.vue";
import AiFaceSwapUse from "@/components/ai-landings/ai-face-swap-use/ai-face-swap-use.vue";
import AiFaceSwapFunny from "@/components/ai-landings/ai-face-swap-funny/ai-face-swap-funny.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";

export default {
    name: "ChatGPTLanding",
    components: {
        AiPopular,
        AiFooter,
        AiHeader,
        AiFaceSwapFunny,
        AiFaceSwapUse,
        AiAdvantages,
        AiHero,
    },
    metaInfo() {
        return {
            title: "FaceSwap - нейросеть для замены лица на любом фото Онлайн | Замените лицо и станьте кем угодно",
        };
    },
};
</script>
