<template lang="pug">
    section.ai-hero.section
        ._wrap.wrap
            ._caption
                h1._title {{ title }}
                portal-target(name="illustration")._mobile-illustration
                ._description(:class="`ai-hero__description_${ai}`")
                    svg-icon(icon-name="star" added-catalog="ai-hero")._description-icon
                    span {{ description }}
                ._footer
                    router-link(to="/welcome/login")
                        ui-button(variant="rounded" :text="btn")._btn
            portal(to="illustration" :disabled="pageWidth >= 700")._illustration-box
                img(:src="image")._illustration
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AIHero",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.hero.titles.${state.ai}`),
        description: state => state.$t(`AILanding.hero.descriptions.${state.ai}`),
        btn: state => state.$t(`AILanding.hero.btns.${state.ai}`),
        image: state => require(`../../../assets/images/ai-hero/${state.ai}.webp`)
    }
};
</script>

<style lang="scss" src="./ai-hero.scss"></style>
