<template lang="pug">
    .pika-content
        ai-header(ai="pika")
        ai-hero(ai="pika")
        ai-pika-variants
        ai-pika-examples
        ai-popular(ai="pika")
        ai-footer(ai="pika")
</template>

<script>
import AiHero from "@/components/ai-landings/ai-hero/ai-hero.vue";
import AiPikaVariants from "@/components/ai-landings/ai-pika-variants/ai-pika-variants.vue";
import AiPikaExamples from "@/components/ai-landings/ai-pika-examples/ai-pika-examples.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";

export default {
    name: "ChatGPTLanding",
    components: {
        AiPopular,
        AiFooter,
        AiHeader,
        AiPikaExamples,
        AiPikaVariants,
        AiHero,
    },
    metaInfo() {
        return {
            title: "Pika - нейросеть для генерации видео онлайн - генерация видео по тексту и фотографии",
        };
    },
};
</script>
