<template lang="pug">
    section.ai-face-swap-use.section
        ._wrap.wrap
            h2._title Как заменить лицо на фото нейросетью
            ._description Испытайте забавные и реалистичные эффекты замены лиц. Станьте тем, кем вы хотите быть!
            ._list
                ._item(v-for="(item, index) in list" :key="index")
                    img(:src="require(`../../../assets/images/ai-face-swap-use/${index+1}.png`)")._item-image
                    ._item-title {{ item.title }}
                    ._item-description(v-html="item.description")
</template>

<script>
export default {
    name: "AiFaceSwapUse",
    data() {
        return {
            list: [
                {
                    title: "1. Загрузите фото",
                    description: "Выберите изображение на компьютере или перетащите его в рамку в чате FaceSwap на <span>Merlinface.com</span>",
                },
                {
                    title: "2. Выберите фото с нужным лицом",
                    description: "Загрузите свое или другое лицо во вторую рамку в чате на <span>Merlinface.com</span>",
                },
                {
                    title: "3. Готово!",
                    description: "Подождите несколько минут и... вуаля! Ваше фото готово",
                },
            ],
        };
    },
};
</script>

<style lang="scss" src="./ai-face-swap-use.scss"></style>
