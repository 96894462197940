<template lang="pug">
    .midjourney-content
        ai-header(ai="midjourney")
        ai-hero(ai="midjourney")
        ai-simple(ai="midjourney")
        ai-examples(ai="midjourney")
        ai-steps(ai="midjourney")
        ai-midjourney-info
        ai-popular(ai="midjourney")
        ai-footer(ai="midjourney")
</template>

<script>
import AiHero from "@/components/ai-landings/ai-hero/ai-hero.vue";
import AiSimple from "@/components/ai-landings/ai-simple/ai-simple.vue";
import AiExamples from "@/components/ai-landings/ai-examples/ai-examples.vue";
import AiSteps from "@/components/ai-landings/ai-steps/ai-steps.vue";
import AiMidjourneyInfo from "@/components/ai-landings/ai-midjourney-info/ai-midjourney-info.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";

export default {
    name: "ChatGPTLanding",
    components: {
        AiPopular,
        AiFooter,
        AiHeader,
        AiMidjourneyInfo,
        AiSteps,
        AiExamples,
        AiSimple,
        AiHero,
    },
    metaInfo() {
        return {
            title: "Midjorney - генерация картинок по тексту онлайн – создавайте уникальные фото за 30 секунд!",
        };
    },
};
</script>
