<template lang="pug">
    section.ai-face-swap-funny.section
        ._wrap.wrap
            h2._title Превратите замену лица в бесконечное веселье
            ._box
                portal._box-image-container(to="image-1" :disabled="pageWidth >= 900")
                    img(src="../../../assets/images/ai-face-swap-funny/1.png")._box-image
                ._box-info
                    ._box-title
                        portal-target(name="image-1")._box-image-container
                        span Создавайте и делитесь смешными мемами
                    
                    ._box-description
                        p._box-p Хотите поделиться смешными шутками или остроумными наблюдениями, наш инструмент для смены лиц — все что вам нужно.
                        p._box-p Всего за несколько кликов вы сможете раскрыть свой творческий потенциал и повысить вовлеченность своей аудитории.
            ._box
                ._box-info
                    ._box-title
                        portal-target(name="image-2")._box-image-container
                        span Познакомтесь с различынми стилями себя
                    ._box-description
                        p._box-p Меняйте свой образ без особых усилий с помощью волшебной функции заммены лица.
                        p._box-p От кинозвезд до супергероев — представьте себя в другом образе. Наш искусственный интеллект, создающий аватарки, в мгновение изменит ваш стиль.
                        p._box-p Выразите себя по-новому, примерив разные варианты макияжа, прически или нарядов.
                portal._box-image-container(to="image-2" :disabled="pageWidth >= 900")
                    img(src="../../../assets/images/ai-face-swap-funny/2.png")._box-image
</template>

<script>
export default {
    name: "AiFaceSwapFunny"
};
</script>

<style lang="scss" src="./ai-face-swap-funny.scss"></style>
