<template lang="pug">
    section.ai-midjourney-info.section
        ._wrap.wrap
            h2._title Midjourney нейросеть — что это или кто?
            ._description
                p._p Нейросеть Midjourney – это передовая AI система, преобразующая текстовые запросы в высококачественные изображения.
                p._p Использует глубокое обучение для генерации картинок от абстракции и скетчей до фотографий и реалистичных картин.
                p._p Работа Midjourney поражает своим качеством, открывающим новые горизонты в text-2-image. Именно его способность воплощать идеи в креативные образы делает его популярным выбором среди дизайнеров, маркетологов, художников. Уникальная нейросеть Midjourney превращает ваш текст в потрясающие фотографии, являясь мостом между вашими мыслями и картинкой.
                p._p Это новая эра для создания контента, где Midjourney становится союзником сммщиков и дизайнеров, помогая им расширять горизонты воображения и ускоряя работу.
</template>

<script>
export default {
    name: "AiMidjourneyInfo",
};
</script>

<style lang="scss">
.ai-midjourney-info {
    $root: &;
    padding: 65px 0;
    line-height: 1.3;

    @media (max-width: 699px) {
        padding: 35px 0;
    }

    &__wrap {
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: 50px;
        color: #fff;
        width: 100%;
        font-weight: 400;
        flex: none;
        text-align: center;

        @media (max-width: 1149px) {
            font-size: 43px;
        }
        @media (max-width: 699px) {
            font-size: 30px;
            b {
                font-weight: 500;
            }
        }
    }

    &__description {
        margin-top: 70px;
        background: #141414;
        border: 1px solid #2e2e2e;
        border-radius: 10px;
        padding: 20px;

        @media (max-width: 879px) {
            margin-top: 50px;
        }
        @media (max-width: 699px) {
            margin-top: 30px;
            padding: 15px;
        }
    }

    &__p {
        color: #fff;
        font-size: 21px;

        &:not(:first-child) {
            margin-top: 16px;
        }

        @media (max-width: 699px) {
            font-size: 16px;
        }
    }
}
</style>
