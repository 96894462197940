<template lang="pug">
    section.ai-pika-examples.section
        ._wrap.wrap
            h2._title Мультфильмы и короткометражки из фото
                br/ от нейросети Pika
            perfect-scrollbar._list
                img(:src="require('../../../assets/images/ai-pika-examples/1.png')")._image
                img(:src="require('../../../assets/images/ai-pika-examples/2.png')")._image
                img(:src="require('../../../assets/images/ai-pika-examples/3.png')")._image
            ._idea
                img(src="../../../assets/images/ai-pika-examples/idea-bg.jpg")._idea-background
                ._idea-content
                    ._idea-title Ваша идея » команда для Pika
                    router-link(to="/welcome/login")._idea-link
                        ui-button(variant="rounded" text="Попробовать Pika")._idea-btn
            ui-button(variant="rounded" text="Попробовать Pika")._idea-btn.-small
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiPikaExamples",
    components: { UiButton },
};
</script>

<style lang="scss" src="./ai-pika-examples.scss"></style>
