<template lang="pug">
    .stable-diffusion-content
        ai-header(ai="stableDiffusion")
        ai-hero(ai="stableDiffusion")
        ai-simple(ai="stableDiffusion")
        ai-examples(ai="stableDiffusion")
        ai-steps(ai="stableDiffusion")
        ai-faq(ai="stableDiffusion")
        ai-popular(ai="stableDiffusion")
        ai-footer(ai="stableDiffusion")
</template>

<script>
import AiHero from "@/components/ai-landings/ai-hero/ai-hero.vue";
import AiSimple from "@/components/ai-landings/ai-simple/ai-simple.vue";
import AiFaq from "@/components/ai-landings/ai-faq/ai-faq.vue";
import AiExamples from "@/components/ai-landings/ai-examples/ai-examples.vue";
import AiSteps from "@/components/ai-landings/ai-steps/ai-steps.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";

export default {
    name: "ChatGPTLanding",
    components: {
        AiPopular,
        AiFooter,
        AiHeader,
        AiSteps,
        AiExamples,
        AiFaq,
        AiSimple,
        AiHero,
    },
    metaInfo() {
        return {
            title: "Stable Diffusion - нейросеть для генерации изображений онлайн - создавайте потрясающие фото без фотографа",
        };
    },
};
</script>
