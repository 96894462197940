<template lang="pug">
    .chat-gpt-content
        ai-header
        ai-hero
        ai-advantages
        ai-simple
        ai-question
        ai-how-work
        ai-faq
        ai-popular
        ai-footer
</template>

<script>
import AiHero from "@/components/ai-landings/ai-hero/ai-hero.vue";
import AiSimple from "@/components/ai-landings/ai-simple/ai-simple.vue";
import AiAdvantages from "@/components/ai-landings/ai-advantages/ai-advantages.vue";
import AiQuestion from "@/components/ai-landings/ai-question/ai-question.vue";
import AiHowWork from "@/components/ai-landings/ai-how-work/ai-how-work.vue";
import AiFaq from "@/components/ai-landings/ai-faq/ai-faq.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";

export default {
    name: "ChatGPTLanding",
    components: {
        AiFooter,
        AiHeader,
        AiPopular,
        AiFaq,
        AiHowWork,
        AiQuestion,
        AiAdvantages,
        AiSimple,
        AiHero,
    },
    metaInfo() {
        return {
            title: "Нейросеть MerlinGPT для ответов на любые вопросы бесплатно - чат с МерлинGPT на русском без VPN",
        };
    },
};
</script>
